<template>
    <div>404 - NOT FOUND</div>
</template>

<script>
    export default {
        name: "PageNotFound"
    }
</script>

<style>
 
</style>